.cardSlider {
    width: 100%;
    height: 100%;
  }
  
  .cardSlider .slick-list {
    height: 100%;
  }
  
  .cardSlider .slick-track {
    height: 100%;
  }
  
  .cardSlider .slick-slide > div {
    height: 100%;
  }
  