.retailrocket-widget-theme-hermitageshop {
  display: none !important;
}

.retailRocket-slider {
  height: 250px;
  position: initial;
}

.retailRocket-slider > .slick-list {
  overflow: visible;
  height: 100%;
}

.retailRocket-slider > .slick-list > .slick-track {
  height: 100%;
  overflow: hidden;
}

.retailRocket-slider > .slick-list > .slick-track > .slick-slide {
  opacity: 0.4;
  transition: all 0.25s;
  position: relative;
}

.retailRocket-slider > .slick-list > .slick-track > .slick-slide:hover {
  opacity: 0.6;
}

.retailRocket-slider
  > .slick-list
  > .slick-track
  > .slick-slide
  > div
  > div:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: block;
  cursor: pointer;
}

.retailRocket-slider
  > .slick-list
  > .slick-track
  > .slick-slide.slick-active
  > div
  > div:before {
  display: none;
}

.retailRocket-slider > .slick-list > .slick-track > .slick-slide.slick-active,
.retailRocket-slider
  > .slick-list
  > .slick-track
  > .slick-slide.slick-active:hover {
  opacity: 1;
}

.retailRocket-slider > .slick-arrow {
}

.retailRocket-slider > .slick-next {
}

.retailRocket-slider > .slick-prev {
}

.retailRocket-slider > .slick-dots {
  position: relative;
  bottom: 18px;
}

.retailRocket-slider > .slick-dots li,
.retailRocket-slider > .slick-dots li button,
.retailRocket-slider > .slick-dots li button:before {
  width: 20px;
  height: 2px;
  padding: 0;
}

.retailRocket-slider > .slick-dots li button:before {
  background-color: #cdcdcd;
  content: '';
  opacity: 1;
  transition: all 0.18s;
}

.retailRocket-slider > .slick-dots li:hover button:before,
.retailRocket-slider > .slick-dots li.slick-active button:before {
  background-color: #e71d85;
  opacity: 1;
}

@media screen and (min-width: 320px) {
  .retailRocket-slider > .slick-list > .slick-track > .slick-slide {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .retailRocket-slider > .slick-list > .slick-track > .slick-slide {
    margin-right: 30px;
  }
  .retailRocket-slider {
    height: 470px;
  }

  .retailRocket-slider > .slick-dots li,
  .retailRocket-slider > .slick-dots li button,
  .retailRocket-slider > .slick-dots li button:before {
    width: 40px;
  }

  .retailRocket-slider > .slick-dots {
    bottom: -45px;
  }
}

/* .retailrocket.retailrocket-widget-theme-hermitageshop {
  font-family: inherit;
  font-size: 15px;
  font-weight: 400;
}

.retailrocket-widget.retailrocket-widget-theme-hermitageshop
  .retailrocket-item-description {
  display: none;
}

.retailrocket.retailrocket-widget-theme-hermitageshop .retailrocket-item-brand {
  display: none;
}

.retailrocket.retailrocket-widget-theme-hermitageshop
  .retailrocket-item-price-currency:after,
.retailrocket-selected-item-price-currency:after {
  content: '₽';
}

.retailrocket.retailrocket-widget-theme-hermitageshop .retailrocket-item-price {
  font-weight: 700;
}

.retailrocket.retailrocket-widget-theme-hermitageshop .retailrocket-item-title {
  margin-top: 20px;
  margin-bottom: 7px;
  text-overflow: unset;
  height: auto;
  white-space: unset;
} */
